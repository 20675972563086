<template>
  <sub-page
      :title="entity.name || ' '"
      :subtitle="entity.name"
      icon="mdi-pencil"
      v-model="$store.state.settings.pageData"
      :loading="!selectedPage.uuid"
  >

    <template #default>

      <!-- HEADER-->
      <v-sheet
          class="d-flex justify-space-between align-center wsRoundedLight px-4 my-3 mb-5 py-2"
          :color="wsLIGHTACCENT"
      >
        <v-sheet :style="`border : 1px solid ${wsDARKLIGHT} !important`" width="400" class="d-flex align-center justify-space-between wsRoundedLight" >
          <v-btn icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <ft-select
              @input="selectedPageId = $event"
              :items="pages"
          >
            <h4 :style="`color : ${wsACCENT}`">
              {{  selectedPage.name  }}
              <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
            </h4>
          </ft-select>


          <v-btn icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>

        <ft-select
            :items="pageActionsSelect"
        >
          <v-btn icon :color="wsACCENT">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </ft-select>

      </v-sheet>

      <!-- CONTENT-->


      <div v-for="(topic,i) in selectedPage.topics" :key="i"
           class="mt-5 ">
        <!-- Topics-->
        <div  class="d-flex align-center pointer">
          <v-btn @click="topic.expanded = !topic.expanded" icon>
            <v-icon :color="wsACCENT">mdi-menu-{{ topic.expanded ? 'down' : 'right'  }}</v-icon>
          </v-btn>

          <ws-text-field-new
              @click.prevent
              @change="addEditTopic(topic , true)"
              v-model="topic.name"
              h3
              text-area
              full-width
          />
        </div>
        <!-- Items-->
        <v-expand-transition>
          <div v-if="topic.expanded">

            <ws-data-table
                :items="topic.items"
                :headers="headers"
                class="mt-5"
                dense
                no-footer
                disable-pagination
            >

              <template #item.select="{item}">
                <ws-check-box
                    :value="item.completed"
                />
              </template>

              <template #item.name="{item}">
                <ws-text-field-new
                    @click.prevent
                    @change="editComponent('name' , $event, item.uuid , i)"
                    v-model="item.name"
                    :placeholder="$t('EnterName')"
                    :color="wsACCENT"
                    bold
                    text-area
                    full-width

                />

              </template>

              <template #item.status="{item}">
                <ft-select
                    @input="editComponent('status' , $event, item.uuid , i)"
                    :value="item.status"
                    :items="statusSelect"
                >
                  <template #default="{text}">
                    <h5 class="text-no-wrap"> {{ text }}
                      <v-icon>mdi-menu-down</v-icon>
                    </h5>
                  </template>

                </ft-select>

              </template>

              <template #item.performers="{item}">

                <ft-select
                    @input="editComponent('performers' , $event, item.uuid , i)"
                    v-model="item.performers"
                    :items="managersSelect"
                    multiple
                >
                  <h5 v-if="item.performers.length === 0 ">{{ $t('Choose')  }}</h5>
                  <div  class="d-flex text-no-wrap shortenText" v-else>
                    <h5 class="mr-1 shortenText">
                      {{ getManagerName(item.performers[0])  }}
                    </h5>
                    <h5 v-if="item.performers.length > 1 ">, +{{  item.performers.length-1 }} </h5>

                  </div>

                </ft-select>



                <h5 class="text-no-wrap" > {{ item.performer}}</h5>
              </template>

              <template #item.date_created="{item}">
                <h5 :style="`color : ${wsACCENT}`" class="text-no-wrap" > {{ MONTH_DAY_TIME(item.date_created , false)  }}</h5>
              </template>

              <template #item.date_start="{item}">
                <ws-date-picker
                    @input="editComponent('date_start' , $event, item.uuid , i)"
                    :value="item.date_start"
                    custom-activator
                    time-stamp
                    :key="item.uuid"
                >
                  <h5 class="text-no-wrap" >
                    {{ item.date_start ?  MONTH_DAY_TIME(item.date_start , false) : $t('DateStart')  }}
                    <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                  </h5>
                </ws-date-picker>
              </template>

              <template #item.date_end="{item}">
                <ws-date-picker
                    @input="editComponent('date_end' , $event, item.uuid , i)"
                    :value="item.date_end"
                    custom-activator
                    time-stamp
                    :key="item.uuid"
                >
                  <h5 class="text-no-wrap" >
                    {{ item.date_end ?  MONTH_DAY_TIME(item.date_end , false) : $t('Deadline')  }}
                    <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                  </h5>
                </ws-date-picker>
              </template>

              <template #item.hover="{item,hover}">
                <v-sheet color="transparent" width="30">
                  <v-btn @click="deleteItem(item.uuid, i)" small v-if="hover" icon :color="wsACCENT">
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-sheet>
              </template>


            </ws-data-table>

            <div class="d-flex align-center justify-center pb-16" style="width: 100%">
              <v-sheet v-if="i === selectedPage.topics.length - 1 || selectedPage.topics.length === 0" class="wsRoundedLight mr-2" :style="`border : 1px solid ${wsACCENT}`">
                <v-btn @click="openNewTopicDialog" text class="noCaps" :color="wsACCENT">
                  <h5>{{ $t('Topic') }}</h5>
                </v-btn>
              </v-sheet>
              <v-sheet class="wsRoundedLight d-flex align-center" :style="`border : 1px solid ${wsACCENT}`">
                <template v-for="(type,index) in componentTypesSelect" >
                  <template v-if="index < 2">
                    <v-btn @click="addComponent(type.value , topic.uuid , i)" text class="noCaps" :color="wsACCENT" :key="index + i + 'type'">
                      <h5>{{ type.text}}</h5>
                    </v-btn>
                    <v-divider  v-if="index !== componentTypesSelect.length - 1" :key="index + 'divider' + i"
                                vertical :style="`border-color : ${wsACCENT}`"  />
                  </template>
                </template>
                <ft-select
                    @input="addComponent($event , topic.uuid , i)"
                    :items="componentTypesSelectMore">
                  <v-btn text class="noCaps pt-1 pr-n2" :color="wsACCENT" :key="i + 'more_types'">
                    <h5 class="mr-n1">{{ $t('More')}} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
                  </v-btn>
                </ft-select>
              </v-sheet>
            </div>


          </div>
        </v-expand-transition>

      </div>


      <v-sheet min-height="150" color="transparent"></v-sheet>


    </template>

    <template #dialog>
      <!-- Page Dialog-->
         <ws-dialog
             v-if="displayPageDialog"
             v-model="displayPageDialog"
             :title="newPage ? $t('AddPage') : $t('EditPage')"
             @save="addEditPage"
         >
           <ws-text-field
               v-model="pageEntityData.name"
               :label="$t('Name')"
               :placeholder="$t('EnterName')"
           />
         </ws-dialog>
      <!-- Topic Dialog-->
          <ws-dialog
              v-if="displayTopicDialog"
              v-model="displayTopicDialog"
              @save="addEditTopic"
              :title="newTopic ? $t('AddTopic') : $t('EditTopic')"
          >
            <ws-text-field
                v-model="topicEntityData.name"
                :label="$t('Name')"
                :placeholder="$t('EnterName')"
            />
          </ws-dialog>
      <!-- Component Dialog-->
    </template>



  </sub-page>


</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "projectViewer",
  props : {
    uuid : {
      type : String,
      default : '',
      newPage : true
    }
  },
  data() {
    return {
      entity : {},
      items : [
        { name : '1. Про пергляд судових розборів зі НАК НАФТОГАЗ' ,  type : 'task', performer : 'Pedro Shantal',  status : 'in_process' ,  date_created : 1681231230, date_end : 1691231230,  uuid : 115 , topic_id : 12  },
      ],
      topics : [],
      pages : [],

      selectedPage : {},
      selectedPageId : null,

      pageEntityData : {},
      topicEntityData : {},
      displayPageDialog : false,
      displayTopicDialog : false,
      newPage : false,
      newTopic : false,

      managersSelect : [],

    }
  },
  computed : {
    headers() {
      return [
        { value : 'select'        , width : 10, padding : 0 , sortable : false},
        { text : this.$t('Name')  , value : 'name' , padding : 0 },
        { text : this.$t('Performer') , value : 'performers' , width: 100 },
        { text : this.$t('Status')  , value : 'status' , padding : 0 , width : 100 },
        { text : this.$t('Start')  , value : 'date_start' ,   width : 100 },
        { text : this.$t('Deadline')  , value : 'date_end' , width : 100},
        { value : 'hover' , width : 10 , padding: 0},
      ]
    },

    pageActionsSelect() {
      return [
        { text : this.$t('AddPage')  , icon : 'mdi-plus',  action : this.openNewPageDialog  },
        { text : this.$t('Settings') , icon : 'mdi-cog',  action : this.openEditPageDialog  },
        { text : this.$t('Delete')   , icon : 'mdi-delete-outline',  action : this.deletePage  }
      ]
    },

    statusSelect() {
      return [
        { text : this.$t('New') , value : 'new' },
        { text : this.$t('InProcess') , value : 'in_process' },
        { text : this.$t('Canceled') , value : 'canceled' },
        { text : this.$t('Paused') , value : 'paused' },
        { text : this.$t('Completed') , value : 'completed' }
      ]
    },
    componentTypesSelect() {
      return [
        { text : this.$t('Task')         , value : 'discipline'     },
        { text : this.$t('Meeting')      , value : 'on_choice_discipline' },
        { text : this.$t('Practice')           , value : 'practice'       },
        { text : this.$t('CourseProject')      , value : 'course_project' },
        { text : this.$t('StateAttestation')   , value : 'state_attestation' },
      ]
    },
    componentTypesSelectMore() {
      return this.componentTypesSelect.filter((_,index) => index >= 2)
    },
  },
  watch : {
    selectedPageId() {
      this.preparePage()
    }
  },
  methods : {
    ...mapActions('project_manager', [
      'GET_PROJECT_EDITOR',
      'GET_PROJECT_PAGE',
      'ADD_EDIT_PROJECT_PAGE' ,
      'ADD_EDIT_PROJECT_PAGE_TOPIC' ,
      'ADD_EDIT_PROJECT_ITEM' ,
      'DELETE_PROJECT_ITEM' ,
    ]),

    async deleteItem(uuid , topicId) {
      let result = await this.DELETE_PROJECT_ITEM(uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.selectedPage.topics[topicId].items.findIndex(el => el.uuid === uuid)

      this.selectedPage.topics[topicId].items.splice(index , 1)
      this.selectedPage = this.COPY(this.selectedPage)

    },

    // page
    async addEditPage() {
      this.pageEntityData.project_id = this.uuid

      let result = await this.ADD_EDIT_PROJECT_PAGE(this.pageEntityData)

      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      if ( this.newPage ) {
        this.pages.push({ value : result.uuid , text : result.name })
        this.selectedPageId = result.uuid
      } else {
        this.selectedPage.name = result.name
        this.pages = this.COPY(this.pages)
      }

      this.displayPageDialog = false
    },
    openNewPageDialog() {
      this.pageEntityData = {}
      this.newPage = true
      this.displayPageDialog = true
    },
    openEditPageDialog() {
      this.pageEntityData = this.COPY(this.selectedPage)
      this.newPage = false
      this.displayPageDialog = true
    },

    // topics
    async addEditTopic() {

      this.topicEntityData.project_page_id = this.selectedPage.uuid

      let result = await this.ADD_EDIT_PROJECT_PAGE_TOPIC(this.topicEntityData)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }

      if ( this.newTopic ) {
        result.expanded = true
        this.selectedPage.topics.push(result)
      } else {
        let index = this.selectedPage.topics.findIndex( el=> el.uuid === result.uuid )
        result.expanded = this.selectedPage.topics[index].expanded
        this.selectedPage.topics[index] = this.COPY(result)
        this.selectedPage = this.COPY(this.selectedPage)
      }

      this.displayTopicDialog = false

    },
    openNewTopicDialog() {
      this.topicEntityData = {}
      this.newTopic = true
      this.displayTopicDialog = true
    },
    openEditTopicDialog(item) {
      this.topicEntityData = this.COPY(item)
      this.newTopic = false
      this.displayTopicDialog = true
    },
    // items
    async addComponent(type , topicId , index) {
      let data = {
        name : this.$t('NewTask'),
        topic_id : topicId
      }
      let result = await this.ADD_EDIT_PROJECT_ITEM(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.selectedPage.topics[index].items.push(result)
      this.selectedPage = this.COPY(this.selectedPage)
    },
    async editComponent(key , value , uuid , topicIndex ) {
      let data = {
        [key] : value,
        uuid : uuid
      }
      let result = await this.ADD_EDIT_PROJECT_ITEM(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.selectedPage.topics[topicIndex].items.findIndex( el=> el.uuid === uuid)
      this.selectedPage.topics[topicIndex].items[index][key] = value
      this.selectedPage = this.COPY(this.selectedPage)
    },




    // Technical
    getManagerName(uuid) {
      let user = this.managersSelect.find(el => el.value === uuid)
      if ( user ) {
        return user.text
      }
      return ''
    },
    getTopicItems(topicId) {
      return this.items.filter(el => el.topic_id === topicId)
    },

    async preparePage() {
      let page = await this.GET_PROJECT_PAGE(this.selectedPageId)
      if ( !page ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.selectedPage = this.COPY(page)
      this.selectedPage.topics.forEach((el,index) => {
        this.selectedPage.topics[index].expanded = true
      })
      this.pages = this.COPY(this.pages)
    },
    async initPage() {
      let result = await this.GET_PROJECT_EDITOR(this.uuid)
      if ( !result ) {
        return this.$t('NetworkError')
      }
      this.entity = result.entity
      this.items = result.items
      this.managersSelect = result.managers_select
      this.pages = result.pages
      if ( this.pages.length > 0 ) {
        this.selectedPageId = this.pages[this.pages.length - 1].value
      }
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>